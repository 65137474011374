import { motion, AnimatePresence, color } from "framer-motion"; // Import motion from framer-motion
import React, { useRef, useState, useEffect } from "react";
import { Canvas, useLoader, useFrame } from "@react-three/fiber";
import { OrbitControls, Environment } from "@react-three/drei";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import {
	faExpand,
	faCompress,
	faPaintBrush,
	faCamera,
	faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import config from "../assets/config";
import HoverButton from "./HoverButton";
import * as TWEEN from "@tweenjs/tween.js"; // Use the full TWEEN namespace
import Stack from "./Stack";
import { FirstPersonControls } from "@react-three/drei"; // Import TrackballControls

function FullScreenButton({ toggleFullScreen }) {
	return (
		<HoverButton
			icon={faExpand}
			config={config}
			onClick={toggleFullScreen}
			style={{
				position: "absolute",
				top: "100%",
				left: "100%",
				fontSize: "30px",
				transform: "translate(-100%, -100%)",
				padding: "10px 15px",
				margin: "-10px",
			}}
		/>
	);
}
function LoadingIndicator() {
	return (
		<div
			style={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				zIndex: 1000,
			}}
		>
			<div className="spinner"></div>
			<style>
				{`
                .spinner {
                  border: 5px solid #f3f3f3;
                  border-top: 5px solid #3498db;
                  border-radius: 50%;
                  width: 50px;
                  height: 50px;
                  animation: spin 2s linear infinite;
                }
                @keyframes spin {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
                }
              `}
			</style>
		</div>
	);
}
function ResizeButton({
	toggleFullScreen,
	toggleMaterialOptions,
	switchCamera,
	cameras,
    admin = false
}) {
	const generalStyle = {
		fontSize: "30px",
		padding: "10px 15px",
		margin: "5px",
		backgroundColor: "rgba(255,255,255,1)",
		color: config.colors.secondary,
	};
	return (
		<motion.div
			initial={{ opacity: 0, y: -50 }} // Start off-screen
			animate={{ opacity: 1, y: 0 }} // Animate into the scene
			exit={{ opacity: 0, y: -50 }} // Animate out of the scene
			transition={{ duration: 0.5 }}
			style={{
				position: "absolute",
				backgroundColor: "rgba(255,255,255,0.1)",
				padding: "5px",
				margin: "10px",
				bottom: "50%",
				right: "0",
				backdropFilter: "blur(100px)",
				borderRadius: "40px",
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
				index: 1000,
				boxShadow: ` -2px -2px 5px rgba(200, 200, 200,0.2), 3px 3px 5px rgba(77, 77, 77, 0.6)`,
			}}
		>
			<HoverButton
				config={config}
				icon={faCompress}
				onClick={toggleFullScreen}
				style={{
					...generalStyle,
					backgroundColor: config.colors.secondary,
					color: "white",
				}}
			/>
			<HoverButton
				config={config}
				icon={faCamera}
				onClick={switchCamera} // Toggles material options
				disabled={cameras.length == 0 ? true : false}
				style={generalStyle}
			/>
			<HoverButton
				config={config}
				icon={faPaintBrush}
				onClick={toggleMaterialOptions} // Toggles material options
				style={generalStyle}
			/>
			{admin && (
				<HoverButton
					config={config}
					icon={faQuestion}
					onClick={() => {window.alert('Shift + Left Mouse to Pan\n L Mouse to Orbit\n Mouse Wheel to Zoom!')}} // Toggles material options
					style={generalStyle}
				/>
			)}
		</motion.div>
	);
}
const isColorCloseToWhite = (color) => {
	// Convert the color to RGB
	const rgb = parseInt(color.slice(1), 16); // Assuming color is in hex format
	const r = (rgb >> 16) & 0xff;
	const g = (rgb >> 8) & 0xff;
	const b = (rgb >> 0) & 0xff;

	// Calculate the brightness of the color
	const brightness = (r * 299 + g * 587 + b * 114) / 1000;

	// Return true if the brightness is close to white
	return brightness > 200; // Adjust the threshold as needed
};
function ColorsTab({ modelOptions, updateMaterial }) {
	const ref = useRef();
	const generalStyle = {
		fontSize: "20px",
		padding: "10px 10px",
		margin: "5px",
	};
	return (
		<motion.div
			ref={ref}
			initial={{ opacity: 0, y: -50 }} // Start off-screen
			animate={{ opacity: 1, y: 0 }} // Animate into the scene
			exit={{ opacity: 0, y: -50 }} // Animate out of the scene
			transition={{ duration: 0.5 }}
			style={{
				position: "absolute",
				backgroundColor: "rgba(255,255,255,0.1)",
				padding: "5px",
				margin: "10px",
				top: "0",
				left: "0",
				backdropFilter: "blur(100px)",
				borderRadius: "30px",
				display: "flex",
				flexDirection: "row",
				alignItems: "flex-start",
				index: 1000,

				boxShadow: ` -2px -2px 5px rgba(200, 200, 200,0.2), 3px 3px 5px rgba(77, 77, 77, 0.6)`,
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					top: "0",
					bottom: "0",
				}}
			>
				{modelOptions?.materials.map((mesh, index) => (
					<Stack direction="v" columnsJustification="start" key={index}>
						<strong style={{ color: "white" }}> {mesh.displayName}:</strong>
						<div style={{ display: "flex", flexWrap: "wrap" }}>
							{mesh.materials.map((material, i) => (
								<HoverButton
									key={i}
									config={config}
									icon={faPaintBrush}
									onClick={() => updateMaterial(mesh.meshName, material.name)}
									style={{
										...generalStyle,

										color: isColorCloseToWhite(material.color)
											? "gray"
											: "white",
										margin: "5px",
									}}
									BackgroundColor={material.color}
								/>
							))}
						</div>
					</Stack>
				))}
			</div>
		</motion.div>
	);
}

function createUI(modelOptions, updateMaterial) {
	const container = document.createElement("div");
	container.id = "material-selector-container";
	container.style.display = "flex";
	container.style.flexDirection = "column";
	container.style.alignItems = "flex-start";
	document.body.appendChild(container);
	const materials = modelOptions.materials;
	materials.forEach((mesh) => {
		const meshRow = document.createElement("div");
		meshRow.style.margin = "10px";
		meshRow.className = "material-select-row";

		const meshLabel = document.createElement("span");
		meshLabel.textContent = mesh.displayName + ": ";
		meshLabel.style.marginRight = "10px";
		meshRow.appendChild(meshLabel);

		const circleContainer = document.createElement("div");
		circleContainer.className = "circle-container";
		meshRow.appendChild(circleContainer);

		mesh.materials.forEach((material) => {
			const colorCircle = document.createElement("div");
			colorCircle.className = "material-color-circle";
			colorCircle.style.width = "20px";
			colorCircle.style.height = "20px";
			colorCircle.style.borderRadius = "50%";
			colorCircle.style.backgroundColor = material.color;
			colorCircle.style.display = "inline-block";
			colorCircle.style.marginRight = "5px";
			colorCircle.style.cursor = "pointer";

			colorCircle.onclick = () => updateMaterial(mesh.meshName, material.name);
			circleContainer.appendChild(colorCircle);
		});

		//   container.appendChild(meshRow);
	});
}
function TweenUpdater() {
	useFrame(() => {
		TWEEN.update();
	});
	return null;
}
function Model({ url, onLoaded }) {
	const dracoLoader = new DRACOLoader();
	dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");
	const gltf = useLoader(GLTFLoader, url, (loader) => {
		loader.setDRACOLoader(dracoLoader);
	});

	useEffect(() => {
		onLoaded(gltf.scene);
	}, [gltf.scene, onLoaded]);

	return <primitive object={gltf.scene} />;
}

function Viewer({ modelUrl, style, admin = false }) {
	const [isFullScreen, setIsFullScreen] = useState(false);
	const [showFrostedLayer, setShowFrostedLayer] = useState(false);
	const [showMaterialOptions, setShowMaterialOptions] = useState(false);
	const [selectedScene, setSelectedScene] = useState(null);
	const [modelOptions, setModelOptions] = useState(null);
	const [uiCreated, setUICreated] = useState(false); // Track if UI has been created
	const [loaded, setLoaded] = useState(false);
	const [loading, setLoading] = useState(true); // Loading state
	const [activeCameraIndex, setActiveCameraIndex] = useState(0);
	const cameraRef = useRef();
	const containerRef = useRef();
	const [cameras, setCameras] = useState([]);
	const controlsRef = useRef(); // Ref to store the actual OrbitControls instance
	const [originalDimensions, setOriginalDimensions] = useState({
		width: "100%",
		height: "100%",
	});
	const toggleFullScreen = () => {
		if (!isFullScreen) {
			// Store the original dimensions before entering full screen
			setOriginalDimensions({
				width: containerRef.current.clientWidth,
				height: containerRef.current.clientHeight,
			});
		}
		setIsFullScreen(!isFullScreen);
	};
	// UseFrame hook to ensure TWEEN gets updated on every frame
	const toggleMaterialOptions = () =>
		setShowMaterialOptions(!showMaterialOptions);
	const introAnimation = () => {
		if (cameraRef.current) {
			cameraRef.current.enabled = false; // Disable controls during animation
		}
		cameraRef.current.updateProjectionMatrix();
		if (cameras[0].start_position != null) {
			cameraRef.current.position.set(
				cameras[0].start_position.x,
				cameras[0].start_position.y,
				cameras[0].start_position.z
			);
		} else {
			cameraRef.current.position.set(0, 40, 4);
		}
		cameraRef.current.lookAt(
			cameras[0].target.x,
			cameras[0].target.y,
			cameras[0].target.z
		);
		cameraRef.current.fov = 50;

		const cameraPosition = cameraRef.current.position;

		new TWEEN.Tween(cameraPosition)
			.to(
				{
					x: cameras[0].position.x,
					y: cameras[0].position.y,
					z: cameras[0].position.z,
				},
				6500
			)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.onUpdate(() => {
				cameraRef.current.position.set(
					cameraPosition.x,
					cameraPosition.y,
					cameraPosition.z
				);
				cameraRef.current.lookAt(0, 1, 0);
			})
			.onComplete(() => {
				console.log("Animation completed");
				if (cameraRef.current) {
					cameraRef.current.enabled = true; // Enable orbit controls after animation
					switchCamera(true);
				}
			})
			.start();
	};
	const switchCamera = (first = false) => {
		// fetchModelOptions(modelUrl);
		console.log("Actual camera", activeCameraIndex);
		if (cameras != null && cameras != undefined) {
			console.log("Switching camera");
			let index = activeCameraIndex;
			console.log("available cameras: ", cameras);
			if (index < cameras.length) {
				index++;
			}
			if (index >= cameras.length) {
				index = 0;
			}
			if (first == true) {
				index = 0;
			}
			console.log("Switching to camera index:", index);
			setActiveCameraIndex(index);
			applyCameraSettings(cameraRef.current, cameras[index]);
		}
	};

	const fetchModelOptions = (modelUrl) => {
		const baseUrl = modelUrl.substring(0, modelUrl.lastIndexOf("/"));
		const jsonUrl = `${baseUrl}/modelOptions.json`;

		fetch(jsonUrl)
			.then((response) => response.json())
			.then((data) => {
				setModelOptions(data);
				console.log("Cameras:", data.cameras);
				setCameras(data.cameras);
				if (data.cameras != null) {
					applyCameraSettings(data.cameras[activeCameraIndex]);
				}
				console.log("Model options:", data);
				setLoaded(true);
				setLoading(false); // Hide loading indicator
			})
			.catch((error) =>
				console.error("Error loading material options:", error)
			);
	};

	const setOrbitControlsLimits = (controls, cameraSettings) => {
		if (controls) {
			controls.enableDamping = true;
			controls.dampingFactor = 0.04;

			// Set target for OrbitControls
			controls.target.set(
				cameraSettings.target.x,
				cameraSettings.target.y,
				cameraSettings.target.z
			); // Example target

			if (cameraSettings.type === "orbit") {
				controls.minDistance = 5;
				controls.maxDistance = 60;
				controls.enablePan = true;
				controls.maxPolarAngle = Math.PI / 2 - 0.005;
			} else if (cameraSettings.type === "stationary") {
				controls.minDistance = 0.1;
				controls.maxDistance = 1;
				controls.enablePan = false;
				controls.maxPolarAngle = 2 * Math.PI;
			}
			if (admin) {
				controls.enablePan = true;
			}
			controls.update(); // Always update the controls after changes
		}
	};

	const applyCameraSettings = (camera, cameraSettings) => {
		if (camera && cameraSettings) {
			camera.position.set(
				cameraSettings.position.x,
				cameraSettings.position.y,
				cameraSettings.position.z
			);

			const controls = controlsRef.current; // Use the ref to get OrbitControls instance
			if (controls) {
				controls.target.set(
					cameraSettings.target.x,
					cameraSettings.target.y,
					cameraSettings.target.z
				);
				setOrbitControlsLimits(controls, cameraSettings);
				controls.update();
			}

			camera.lookAt(
				cameraSettings.target.x,
				cameraSettings.target.y,
				cameraSettings.target.z
			);

			camera.updateMatrixWorld();
			camera.updateProjectionMatrix();
		}
	};
	useEffect(() => {
		if (loaded && cameraRef.current) {
			introAnimation();
		}
	}, [loaded]);

	// Create UI only when materialOptions is available and the UI hasn't been created yet
	useEffect(() => {
		if (modelOptions && !uiCreated) {
			createUI(modelOptions, updateMaterial); // Create UI once
			setUICreated(true); // Mark UI as created to prevent future calls
		}
	}, [modelOptions]);

	const handleModelLoaded = (scene) => {
		if (!loaded) {
			console.log("Model loaded");
			setSelectedScene(scene);
			fetchModelOptions(modelUrl); // Fetch the material options once the model is loaded
			console.log("Cameras:", cameras);
		}
	};

	const updateMaterial = (meshName, materialName) => {
		if (selectedScene && modelOptions) {
			const selectedMesh = selectedScene.getObjectByName(meshName);
			const materialData = modelOptions.materials
				.find((mesh) => mesh.meshName === meshName)
				.materials.find((mat) => mat.name === materialName);

			if (selectedMesh) {
				selectedMesh.material.color.set(materialData.color);
				selectedMesh.material.roughness = materialData.roughness;
				selectedMesh.material.metalness = materialData.metalness;
				selectedMesh.material.needsUpdate = true;
			}
		}
	};

	return (
		<div style={{ padding: "10px", ...style }}>
			<AnimatePresence>
				<motion.div
					ref={containerRef}
					className={`Viewer ${isFullScreen ? "fullscreen" : ""}`}
					style={{
						overflow: "hidden",
						width: "100%",
						height: "100%",
						position: isFullScreen ? "fixed" : "relative",
						zIndex: isFullScreen ? 1000 : "auto",
						background: "linear-gradient(135deg, #1f1f2e 0%, #141629 100%)",
						borderRadius: "30px",
						boxShadow: ` -2px -2px 5px rgba(200, 200, 200,0.2), 3px 3px 5px rgba(77, 77, 77, 0.6)`,
					}}
					animate={{
						borderRadius: isFullScreen ? "0px" : "30px",
						width: isFullScreen ? "100vw" : originalDimensions.width,
						height: isFullScreen ? "100vh" : originalDimensions.height,
						maxWidth: isFullScreen ? "100vw" : "100%",
						top: isFullScreen ? 0 : "",
						bottom: isFullScreen ? 0 : "",
						right: isFullScreen ? 0 : "",
						left: isFullScreen ? 0 : "",
					}}
					transition={{ duration: 0.2 }}
					onAnimationStart={() => {
						setShowFrostedLayer(true);
					}}
				>
					{
						<motion.div
							initial={{ opacity: 0 }}
							animate={{
								opacity: showFrostedLayer ? [1, 1, 0] : 0,
								zIndex: showFrostedLayer ? 1 : 0,
							}}
							transition={{ duration: 1, times: [0.05, 0.8, 0.95] }}
							style={{
								position: "absolute",
								opacity: 1,
								width: "100%",
								height: "100%",
								backdropFilter: "blur(1000px)",
								backgroundColor: "rgba(71, 142, 196, 0.1)",
								zIndex: 1,
							}}
							onAnimationComplete={() => setShowFrostedLayer(false)}
						/>
					}
					{/* Material Options displayed within Canvas when toggled */}
					{/* Show loading indicator while loading */}
					{loading && <LoadingIndicator />}
					<Canvas
						onCreated={({ camera, gl }) => {
							cameraRef.current = camera;
							// Ensuring Canvas resizes with container changes
							gl.setSize(
								containerRef.current.clientWidth,
								containerRef.current.clientHeight
							);
						}}
					>
						<Environment preset="sunset" />
						<OrbitControls ref={controlsRef} enableZoom={isFullScreen} />
						<Model url={modelUrl} onLoaded={handleModelLoaded} />
						<TweenUpdater /> {/* Add the TweenUpdater to call TWEEN.update() */}
					</Canvas>
					<AnimatePresence>
						{showMaterialOptions && isFullScreen && (
							<ColorsTab
								modelOptions={modelOptions}
								updateMaterial={updateMaterial}
							/>
						)}
						{!isFullScreen && (
							<FullScreenButton toggleFullScreen={toggleFullScreen} />
						)}
					</AnimatePresence>
					{/* FullScreen Button */}

					{!isFullScreen && (
						<FullScreenButton toggleFullScreen={toggleFullScreen} />
					)}
					{isFullScreen && (
						<ResizeButton
							toggleFullScreen={toggleFullScreen}
							toggleMaterialOptions={toggleMaterialOptions}
							switchCamera={switchCamera}
							cameras={cameras}
                            admin={admin}
						/>
					)}
				</motion.div>
			</AnimatePresence>
		</div>
	);
}

export default Viewer;
