import React, { useState } from 'react';
import {
    Edit,
    FunctionField,
    minValue,
    SimpleForm,
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    BooleanInput,
    useRecordContext,
    TextField,
    Show,
    TabbedShowLayout,
    Tab,
    ArrayField,
    Datagrid,
    NumberField,
    List,
    SingleFieldList,
    ChipField,
    ShowButton,
    EditButton,
    DeleteButton,
    Create,
    TabbedForm,
    FormTab,
    BooleanField,
    required,
    useNotify,
    useRefresh,
    useDataProvider,
    Button,
    FormDataConsumer,
    SelectArrayInput,
    Logout,
    WithRecord
} from 'react-admin';
import { ConfigurationsTab, ConfigurationShow } from "./ra_configurations"
import { SystemsTab, SystemsTabInlineEdit, SystemsShow } from "./ra_systems"
import { Dialog, DialogTitle, DialogContent, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Viewer from './3dViewer2';
import usePublic from './usePublicConfig';
export const ProjectsEdit = (props) => (
    <Edit {...props} title="Edit Project">
        <TabbedForm>
            {/* General Tab */}
            <FormTab label="General">
                <TextInput source="name" label="Project Name" fullWidth />
                <TextInput source="description" label="Description" multiline fullWidth />
                <TextInput source="model_full" label="Project 3d Model" fullWidth />
                <TextInput source="nameid" label="Project Nameid" fullWidth />

                {/* Year Input Field */}
                <NumberInput
                    source="year"
                    label="Year"
                    validate={[required(), minValue(1900)]}
                    max={new Date().getFullYear()}
                    fullWidth
                />
            </FormTab>

            {/* Configurations Tab */}
            <FormTab label="Configurations">
                <ConfigurationsTab />
            </FormTab>

            {/* Systems Tab */}
            <FormTab label="Systems">
                <SystemsTab />
            </FormTab>
        </TabbedForm>
    </Edit>
);
export const ProjectShow = (props) => {
    const record = useRecordContext(props);
    const [openConfig, setOpenConfig] = useState(false);
    const [selectedConfig, setSelectedConfig] = useState(null);
    // Function to handle configuration selection
    const handleSelectConfig = (event) => {
        const configId = event.target.value;
        const selected = record.configurations.find(config => config.id === configId);
        setSelectedConfig(selected);
    };

    const handleClickConfig = (config) => {
        setSelectedConfig(config);
        setOpenConfig(true);
    };

    return (
        <Show {...props} title="Project Details">
            <TabbedShowLayout>
                {/* Project Details Tab */}
                <Tab label="Project Details">
                    <TextField source="name" label="Project Name" />
                    <TextField source="year" label="Project Year" />
                    <TextField source="description" label="Description" />
                    {/* Other project fields */}
                </Tab>
                {/*  */}
                {/* Configurations Tab */}
                <Tab label="Configurations">
                    <ConfigurationsTab />
                </Tab>
                {/* Configurations Tab */}
                <Tab label="Configurations">
                    <ArrayField source="configurations">
                        <SingleFieldList>
                            <ChipField
                                source="configName"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    const config = event.record;
                                    handleClickConfig(config);
                                }}
                            />
                        </SingleFieldList>
                    </ArrayField>
                    {openConfig && (
                        <Dialog open={openConfig} onClose={() => setOpenConfig(false)} fullWidth maxWidth="md">
                            <DialogTitle>Configuration Details</DialogTitle>
                            <DialogContent>
                                <ConfigurationShow
                                    configuration={selectedConfig}
                                    allSystems={record.systems}
                                />
                            </DialogContent>
                        </Dialog>
                    )}
                </Tab>

                {/* Systems Tab */}
                <Tab label="Systems">
                    <SystemsShow/>
                </Tab>
                {/* Systems Tab */}
                <Tab label="3dModel">
                    {true &&
                        <ModelView/>
                    }
                </Tab>
                {/* Add more tabs as needed */}
            </TabbedShowLayout>
        </Show>
    );
};
// Projects List Component
export const ProjectsList = (props) => (
    <List {...props} title="Projects">
        <Datagrid rowClick="show">
            <TextField source="name" label="Project Name" />
            <TextField source="year" label="Year" />
            {/* Display configuration names */}
            {/* <ArrayField source="configurations" label="Configurations">
                <SingleFieldList>
                    <ChipField source="configName" />
                </SingleFieldList>
            </ArrayField> */}
            {/* Actions */}
            <ShowButton />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>

);
export const ProjectsCreate = (props) => (
    <Create {...props} title="Create Project">
        <TabbedForm>
            {/* Project Details Tab */}
            <FormTab label="Project Details">
                {/* Project Name*/}
                <TextInput
                    source="name"
                    label="Project Name"
                    validate={required()}
                    fullWidth
                />
                {/* Year*/}
                <NumberInput
                    source="year"
                    label="Year"
                    validate={[required(), minValue(1900)]}
                    max={new Date().getFullYear()}
                    fullWidth
                />
                {/*Description*/}
                <TextInput
                    source="description"
                    label="Description"
                    multiline
                    fullWidth
                />
                {/* Project Name*/}
                <TextInput
                    source="nameid"
                    label="Project Name Id"
                    validate={required()}
                    fullWidth
                />
                <TextInput
                    source="model_full"
                    label="Project 3d Model"
                    fullWidth
                />

            </FormTab>

            {/* Systems Tab */}
            <FormTab label="Systems">
                <ArrayInput source="systems">
                    <SimpleFormIterator>
                        <TextInput
                            source="systemName"
                            label="System Name"
                            validate={required()}
                            fullWidth
                        />
                        <NumberInput
                            source="massMultiplier"
                            label="Mass Multiplier"
                            defaultValue={1}
                            validate={[required(), minValue(0)]}
                        />
                        <BooleanInput
                            source="required"
                            label="Required"
                            defaultValue={false}
                        />
                        {/* Initialize sum-up fields */}
                        <NumberInput
                            source="sumup.sumup_mass"
                            label="Total Mass"
                            defaultValue={0}
                            disabled
                        />
                        <NumberInput
                            source="sumup.sumup_price"
                            label="Total Price"
                            defaultValue={0}
                            disabled
                        />
                        {/* Other system fields */}
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>

            {/* Configurations Tab */}
            <FormTab label="Configurations">
                <ArrayInput source="configurations">
                    <SimpleFormIterator>
                        {/* Fields for configuration */}
                        <TextInput
                            source="configName"
                            label="Configuration Name"
                            validate={required()}
                            fullWidth
                        />
                        <TextInput
                            source="description"
                            label="Description"
                            multiline
                            fullWidth
                        />
                        {/* Initialize sum-up fields */}
                        <NumberInput
                            source="sumup.sumup_mass"
                            label="Total Mass"
                            defaultValue={0}
                            disabled
                        />
                        <NumberInput
                            source="sumup.sumup_price"
                            label="Total Price"
                            defaultValue={0}
                            disabled
                        />

                        {/* Systems Selection */}
                        <FormDataConsumer>
                            {({ formData }) => {
                                const systems = formData.systems || [];
                                const choices = systems.map((system, index) => ({
                                    id: index,
                                    name: system.systemName || `System ${index + 1}`,
                                }));
                                return (
                                    <SelectArrayInput
                                        label="Systems"
                                        source="systemIndexes"
                                        choices={choices}
                                        optionText="name"
                                        optionValue="id"
                                    />
                                );
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>

            {/* Remove Authorized Users Tab */}
            {/* Add more tabs as needed */}
        </TabbedForm>
    </Create>
);

export const ModelView = () => {
    const record = useRecordContext(); // The project or system containing the items
    if(record.nameid && record.model_full){

        const model = `${process.env.PUBLIC_URL}/content/projects/${record.nameid}/3dModels/${record.model_full}`;
        console.log('MODELURL', model);
        return (
            <div style={{
                    height: '100%',
                    width : '100%'
                }}>
            {model &&
                <Viewer modelUrl={model}
                admin={true}
                style={{
                    minHeight: '60vh',
                    height: '60vh',
                    width : '100%'
                }}
                />
            }
            </div>

        );
    }
    return(<></>);

};
