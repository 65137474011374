import React, { useState, useEffect } from 'react';
import Stack from './Stack';
import config from '../assets/config';
import Gallery from './Gallery';
import Viewer from './3dViewer2';
import Card from './Card';
import useResponsive from './useResponsive';

function ProjectPage({ project }) {
    const isMobile = useResponsive();
    const [markdownFileContent, setMarkdownFileContent] = useState('');
    const [extraMarkdownFileContent, setExtraMarkdownFileContent] = useState([]);

    useEffect(() => {
        if (project.markdownFile && markdownFileContent == '') {
            fetchMarkdown(`${process.env.PUBLIC_URL}/content/projects/${project.nameid}/${project.markdownFile}`, setMarkdownFileContent);
        }

        if (project.markdownFiles && project.markdownFiles.length > 0) {
            const fetchAllMarkdowns = async () => {
                const promises = project.markdownFiles.map(file =>
                    fetch(`${process.env.PUBLIC_URL}/content/projects/${project.nameid}/${file}`)
                        .then(response => response.text())
                        .catch(error => console.error('Error loading extra markdown file:', error))
                );
                const contents = await Promise.all(promises);
                setExtraMarkdownFileContent(contents);
            };

            fetchAllMarkdowns();
        }
    }, [project, markdownFileContent]);

    if (!project) {
        return <div>Project not found</div>;
    }

    // Function to fetch markdown file
    const fetchMarkdown = (filePath, setContent) => {
        if (!filePath) return;
        fetch(filePath)
            .then(response => response.text())
            .then(text => setContent(text))
            .catch(error => console.error('Error loading the markdown file:', error));
    };

    // Unconditionally call the useEffect hook for main markdown file
/*
if( markdownFileContent == '' && project.markdownFile){
    fetchMarkdown(`${process.env.PUBLIC_URL}/content/projects/${project.nameid}/${project.markdownFile}`, setMarkdownFileContent);
        // Unconditionally call the useEffect hook for extra markdown files

    if (project.markdownFiles && project.markdownFiles.length > 0) {
        const fetchAllMarkdowns = async () => {
            const promises = project.markdownFiles.map(file =>
                fetch(`${process.env.PUBLIC_URL}/content/projects/${project.nameid}/${file}`)
                    .then(response => response.text())
                    .catch(error => console.error('Error loading extra markdown file:', error))
            );
            const contents = await Promise.all(promises);
            setExtraMarkdownFileContent(contents);
        };

        fetchAllMarkdowns();
    }
}*/

    const projectGallery = project.gallery.map(image => `${process.env.PUBLIC_URL}/content/projects/${project.nameid}/${image}`);
    const project3dModel = `${process.env.PUBLIC_URL}/content/projects/${project.nameid}/3dModels/model.glb`;

    return (
        <Stack
            direction="v"
            style={{ marginTop: '10px', width: '100%' }}
            title={project.title}
            titleStyle={{ color: 'white' }}
            justifyContent='center'
            parentStyle={{ display: 'flex', justifyContent: 'center' }}
        >
            {/* GALLERY */}
            {config.mainGallery.length <= 0 &&
                <Stack
                    direction='h'
                    enableScrollButtons='true'
                    style={{ width: '100%' }}
                    config={config}
                    autoScroll={true}
                >
                    {projectGallery.map((image, index) => (
                        <div key={index}
                            style={{
                                width: isMobile ? 'calc(90vw - 20px)' : '',
                                height: isMobile ? '' : '50vh',
                                margin: isMobile ? '10px 10px' : '10px 30px',
                                aspectRatio: '16/9'
                            }}
                        >
                            <img src={image} alt='gallery'
                                className='object-cover rounded-[30px] shadow-[-2px_-2px_10px_rgba(255,_255,255,_0.1),_2px_5px_5px_rgba(0,0,0,_0.2)]'
                                style={{
                                    aspectRatio: '16/9',
                                    height: '100%',
                                }}
                            />
                        </div>
                    ))}
                </Stack>
            }

            {/* MARKDOWN 1 */}
            <Stack direction='auto' style={{ width: '100%' }}>
                {project.markdownFile && markdownFileContent &&
                    <Card config={config} style={{ width: isMobile ? '90vw' : '' }} direction='h' description={markdownFileContent} />
                }
            </Stack>
            <Stack direction='auto' style={{ width: '100%' }}>
                {project3dModel &&
                    <Viewer modelUrl={project3dModel}
                        style={{
                            width: isMobile ? '90vw' : '100%',
                            padding: isMobile ? '0px' : '10px',
                            height: isMobile ? '50vh' : '100%',
                            minWidth: isMobile ? '' : '30vw',
                        }}
                    />
                }
            </Stack>
            {/* EXTRA MARKDOWNS */}
            {extraMarkdownFileContent != [] && <Stack direction='auto' style={{ width: '100%' }}>
                {extraMarkdownFileContent.map((content, index) => (
                    <Card key={index} config={config} style={{ width: isMobile ? '90vw' : '' }} direction='h' description={content} />
                ))}
            </Stack>}

            {/* GALLERY */}
            {project.gallery && <Gallery style={{}} modalEnabled={true} images={projectGallery} />}
        </Stack>
    );
}

export default ProjectPage;
