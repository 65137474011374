// src/components/users.js
import React, {useState} from 'react';
import {
    Show,
    SimpleShowLayout,
    List,
    Datagrid,
    TextField,
    EditButton,
    DeleteButton,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    EmailField,
    Toolbar ,
    ArrayField,
    SingleFieldList,
    ChipField,
    SelectInput,
    ArrayInput,
    SimpleFormIterator,
    DateTimeInput,
    FunctionField,
    BooleanInput, TabbedForm, FormTab, ReferenceArrayInput, AutocompleteArrayInput
} from 'react-admin';
import CacheSaveButton from './ra_CacheSaveButton';
// import PendingChangesList from './ra_PendingChangesList';
export const roleNames = {
    0: 'Guest',
    1: 'Boat Builder',
    98: 'Project Manager',
    99: 'Admin',
    // Add other roles as needed
};
const RoleField = (props) => (
    <FunctionField
        {...props}
        render={(record) => roleNames[record.role] || 'Unknown'}
    />
);
// const CustomToolbar = ({ onCollectChanges }) => (
//     <Toolbar>
//         <CacheSaveButton onCollectChanges={onCollectChanges} />
//         {/* Optionally, you can disable the default SaveButton */}
//         {/* <SaveButton disabled /> */}
//     </Toolbar>
// );

export const UsersList = (props) => (
    <List {...props} title="Users">
        <Datagrid rowClick="edit">
            {/* <TextField source="id" label="ID" /> */}
            <TextField source="username" label="Username" />
            <TextField source="name" label="Name" />
            <TextField source="surname" label="Surname" />
            <EmailField source="email" label="Email" />
            <RoleField source="role" label="Role" />
            <ArrayField source="accessible_projects" label="Accessible Projects">
                <SingleFieldList>
                    <ChipField source="" />
                </SingleFieldList>
            </ArrayField>
            {/* Add other fields as needed */}
        </Datagrid>
    </List>
);
export const UsersEdit = (props) => {
    // const [pendingChanges, setPendingChanges] = useState([]);
    // const collectChanges = (values) => {
    //     setPendingChanges((prevChanges) => [...prevChanges, values]);
    // };
    return(

    <Edit {...props} title="Edit User">
        <TabbedForm>
            <FormTab label="General">
                <TextInput source="id" />
                <TextInput source="username" />
                <TextInput source="name" />
                <TextInput source="surname" />
                <TextInput source="email" />
            </FormTab>
            <FormTab label="Role">
                <SelectInput
                    source="role"
                    choices={[
                        { id: 0, name: 'Guest' },
                        { id: 1, name: 'Customer' },
                        { id: 95, name: 'Yard' },
                        { id: 96, name: 'Staff' },
                        { id: 97, name: 'Designer' },
                        { id: 98, name: 'Project Manager' },
                        { id: 99, name: 'Admin' },
                    ]}
                />
                <ReferenceArrayInput
                    label="Accessible Projects"
                    source="accessible_projects"
                    reference="projects"
                >
                    <AutocompleteArrayInput
                        optionText="name"
                        filterToQuery={(searchText) => ({ name: searchText })}
                    />
                </ReferenceArrayInput>
            </FormTab>
            <FormTab label="Account">
                <DateTimeInput source="account_stats.created_at" />
                <DateTimeInput source="account_stats.last_login" />
                <BooleanInput source="account_stats.email_verified" />
                <BooleanInput source="account_stats.is_active" />
                <BooleanInput source="account_stats.is_blocked" />
            </FormTab>
            <FormTab label="Address">
                {/* Address Fields */}
                <TextInput source="address.street" />
                <TextInput source="address.city" />
                <TextInput source="address.state" />
                <TextInput source="address.postal_code" />
                <TextInput source="address.country" />
            </FormTab>
            <FormTab label="Preferences">
                {/* Preferences */}
                <TextInput source="preferences.language" />
                <TextInput source="preferences.theme" />
            </FormTab>

            {/* Add other fields as needed */}
        </TabbedForm>
    </Edit>
)};
// export const UsersEdit = (props) => (
//     <Edit {...props}>
//         <TabbedForm>
//             <FormTab label="General">
//                 <TextInput source="username" />
//                 <TextInput source="email" />
//                 {/* Other general fields */}
//             </FormTab>
//             <FormTab label="Permissions">
//                 <SelectArrayInput
//                     source="accessible_projects"
//                     choices={[
//                         // Choices will be populated dynamically
//                     ]}
//                 />
//                 {/* Other permission fields */}
//             </FormTab>
//             {/* Additional tabs as needed */}
//         </TabbedForm>
//     </Edit>
// );

export const UsersCreate = (props) => (
    <Create {...props} title="Create User">
        <SimpleForm>
            <TextInput source="id" />
            <TextInput source="username" />
            <TextInput source="name" />
            <TextInput source="surname" />
            <TextInput source="email" />
            <SelectInput
                source="role"
                choices={[
                    { id: 0, name: 'Guest' },
                    { id: 1, name: 'Boat Builder' },
                    { id: 99, name: 'Admin' },
                ]}
                defaultValue={0}
            />
            <ArrayInput source="accessible_projects">
                <SimpleFormIterator>
                    <TextInput label="Project ID" />
                </SimpleFormIterator>
            </ArrayInput>
            {/* Account Stats */}
            <BooleanInput source="account_stats.email_verified" defaultValue={false} />
            <BooleanInput source="account_stats.is_active" defaultValue={true} />
            <BooleanInput source="account_stats.is_blocked" defaultValue={false} />
            {/* Address Fields */}
            <TextInput source="address.street" />
            <TextInput source="address.city" />
            <TextInput source="address.state" />
            <TextInput source="address.postal_code" />
            <TextInput source="address.country" />
            {/* Preferences */}
            <TextInput source="preferences.language" defaultValue="en" />
            <TextInput source="preferences.theme" defaultValue="light" />
            {/* Add other fields as needed */}
        </SimpleForm>
    </Create>
);

export const UsersShow = (props) => (
    <Show {...props} title="Users Details">
        <SimpleShowLayout>
            <TextField source="id" label="ID" />
            <TextField source="name" label="Project Name" />
            {/* Configurations */}
            <ArrayField source="configurations" label="Configurations">
                <Datagrid>
                    <TextField source="configuration_name" label="Configuration Name" />
                    {/* Add fields */}
                </Datagrid>
            </ArrayField>
            {/* Systems */}
            <ArrayField source="systems" label="Systems">
                <Datagrid>
                    <TextField source="name" label="System Name" />
                    <TextField source="design_mass" />
                    {/* Add fields */}
                </Datagrid>
            </ArrayField>
            {/* Add other fields as needed */}
        </SimpleShowLayout>
    </Show>
);
